import { createTheme } from "@mui/material";

const uiTheme = createTheme({
    palette: {
        primary: {
            main: '#222'
        }
    }
});

export default uiTheme;