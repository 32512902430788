import logo from './logo.svg';
import './App.css';
import { AppBar, Box, Container, CssBaseline, Icon, ThemeProvider, Typography, colors } from '@mui/material';
import { Fragment } from 'react';
import uiTheme from './ui_theme';

function App() {
  return (
    <ThemeProvider theme={uiTheme}>
      <AppBar position='static'>
        <Container maxWidth='xl'>
          <Typography variant='h6' sx={{letterSpacing: '.2rem'}}>
            Admiral Arkona
          </Typography>
        </Container>
      </AppBar>
      <Container maxWidth='xl'>
        <Box sx={{my: 1}}>
          <img src='static/media/bg1.jpg' width="100%"/>
          <Typography variant='body1'>
            Historische Seeschlachten in VR.<br/>
            Coming soon...
          </Typography>
          <hr/>
          <Typography variant='body2'>
            © 2023 - {(new Date().getFullYear())} HI3M
          </Typography>
        </Box>
      </Container>
    </ThemeProvider>
  );
}

export default App;
